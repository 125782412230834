import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const EditExpensePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [payerId, setPayerId] = useState("");
  const [description, setDescription] = useState("");
  const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState("");
  const [selectedParticipantId, setSelectedParticipantId] = useState("");
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    fetchExpense();
    fetchExpenseTypes();
    fetchParticipants();
  }, []);

  const fetchExpense = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/expenses/${id}`
      );
      const expense = response.data;
      setTitle(expense.title);
      setAmount(expense.amount);
      setPayerId(expense.payer_id);
      setDescription(expense.description);
      setSelectedExpenseTypeId(expense.expense_type_id);
      setSelectedParticipantId(expense.participant_id);
    } catch (error) {
      console.error("Error fetching expense:", error);
    }
  };

  const fetchExpenseTypes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/expense-types`
      );
      setExpenseTypes(response.data);
    } catch (error) {
      console.error("Error fetching expense types:", error);
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/participants`
      );
      setParticipants(response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const handleUpdateExpense = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/expenses/${id}`, {
        title,
        amount,
        payerId,
        description,
        expenseTypeId: selectedExpenseTypeId,
        participantId: selectedParticipantId,
      });
      navigate("/");
    } catch (error) {
      console.error("Error updating expense:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Edit Expense</h2>
      <form onSubmit={handleUpdateExpense}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title:
          </label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">
            Amount:
          </label>
          <input
            type="number"
            id="amount"
            className="form-control"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="payer" className="form-label">
            Payer:
          </label>
          <select
            className="form-select"
            id="payer"
            value={payerId}
            onChange={(e) => setPayerId(e.target.value)}
          >
            <option value="">Select a payer</option>
            {participants.map((participant) => (
              <option key={participant.id} value={participant.id}>
                {participant.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description:
          </label>
          <textarea
            className="form-control"
            id="description"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="expenseType" className="form-label">
            Expense Type:
          </label>
          <select
            className="form-select"
            id="expenseType"
            value={selectedExpenseTypeId}
            onChange={(e) => setSelectedExpenseTypeId(e.target.value)}
          >
            <option value="">Select an expense type</option>
            {expenseTypes.map((expenseType) => (
              <option key={expenseType.id} value={expenseType.id}>
                {expenseType.type}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="participant" className="form-label">
            Participant:
          </label>
          <select
            className="form-select"
            id="participant"
            value={selectedParticipantId}
            onChange={(e) => setSelectedParticipantId(e.target.value)}
          >
            <option value="">Select a participant</option>
            {participants.map((participant) => (
              <option key={participant.id} value={participant.id}>
                {participant.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary">
          Update Expense
        </button>
      </form>
    </div>
  );
};

export default EditExpensePage;

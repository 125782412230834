import React, { useState, useEffect } from "react";
import axios from "axios";

const AddExpenseModal = ({ show, onClose, fetchExpenses }) => {
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [payerId, setPayerId] = useState("");
  const [description, setDescription] = useState("");
  const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState("");
  const [selectedParticipantId, setSelectedParticipantId] = useState("");
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    fetchExpenseTypes();
    fetchParticipants();
  }, []);

  const fetchExpenseTypes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/expense-types`
      );
      setExpenseTypes(response.data);
    } catch (error) {
      console.error("Error fetching expense types:", error);
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/participants`
      );
      setParticipants(response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const handleAddExpense = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/expenses`, {
        title,
        amount,
        payerId,
        description,
        expenseTypeId: selectedExpenseTypeId,
        participantId: selectedParticipantId,
      });
      onClose();
      window.location.reload(true);
      // fetchExpenses();
    } catch (error) {
      console.error("Error adding expense:", error);
    }
  };

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      id="addExpenseModal"
      tabIndex="-1"
      aria-labelledby="addExpenseModalLabel"
      aria-hidden={!show}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addExpenseModalLabel">
              Add New Expense
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Subject:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="amount" className="form-label">
                  Amount:
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="payer" className="form-label">
                  Paid by:
                </label>
                <select
                  className="form-select"
                  id="payer"
                  value={payerId}
                  onChange={(e) => setPayerId(e.target.value)}
                >
                  <option value="">Select a payer</option>
                  {participants.map((participant) => (
                    <option key={participant.id} value={participant.id}>
                      {participant.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description:
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="3"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="expenseType" className="form-label">
                  Expense Type:
                </label>
                <select
                  className="form-select"
                  id="expenseType"
                  value={selectedExpenseTypeId}
                  onChange={(e) => setSelectedExpenseTypeId(e.target.value)}
                >
                  <option value="">Select an expense type</option>
                  {expenseTypes.map((expenseType) => (
                    <option key={expenseType.id} value={expenseType.id}>
                      {expenseType.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="participant" className="form-label">
                  Paid to:
                </label>
                <select
                  className="form-select"
                  id="participant"
                  value={selectedParticipantId}
                  onChange={(e) => setSelectedParticipantId(e.target.value)}
                >
                  <option value="">Select a participant</option>
                  {participants.map((participant) => (
                    <option key={participant.id} value={participant.id}>
                      {participant.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Close
            </button>
            <button className="btn btn-primary" onClick={handleAddExpense}>
              Add Expense
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpenseModal;

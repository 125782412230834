// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import EditExpensePage from "./pages/EditExpensePage";
import LoginPage from "./pages/LoginPage"; // Import the login page component
import ErrorPage from "./pages/ErrorPage";
import { jwtDecode } from "jwt-decode";

function App() {
  const token = localStorage.getItem("token");
  let username;

  // Check if token exists
  if (token) {
    // Decode the token to extract the username
    const decodedToken = jwtDecode(token);
    username = decodedToken.username;
  }

  // Check if token and username exist
  const isLoggedIn =
    token && username && username === process.env.REACT_APP_API;

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/" /> : <LoginPage />}
        />
        <Route
          path="/"
          element={
            isLoggedIn ? (
              <HomePage username={username} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/edit-expense/:id"
          element={
            isLoggedIn ? (
              <EditExpensePage username={username} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;

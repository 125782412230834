import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import "./App.css";

import AddExpenseTypeModal from "./AddExpenseTypeModal";
import AddExpenseModal from "./AddExpenseModal";
import AddParticipantModal from "./AddParticipantModal";
import ExpenseDetailModal from "./ExpenseDetailModal";

const HomePage = () => {
  const [showAddExpenseTypeModal, setShowAddExpenseTypeModal] = useState(false);
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const [showExpenseDetailModal, setShowExpenseDetailModal] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState(null);

  const totalExpenses = expenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );

  useEffect(() => {
    fetchExpenses();
    fetchParticipants();
    fetchExpenseTypes();
  }, []);

  const fetchExpenses = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/expenses`
      );
      setExpenses(response.data);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/participants`
      );
      setParticipants(response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const fetchExpenseTypes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/expense-types`
      );
      setExpenseTypes(response.data);
    } catch (error) {
      console.error("Error fetching expense types:", error);
    }
  };

  const deleteExpense = async (expenseId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/expenses/${expenseId}`
      );
      fetchExpenses(); // Fetch expenses again after deletion
    } catch (error) {
      console.error("Error deleting expense:", error);
    }
  };

  const handleRowClick = (expense) => {
    setSelectedExpense(expense);
    setShowExpenseDetailModal(true);
  };

  const logout = () => {
    // Clear localStorage
    localStorage.clear();
    // Redirect to login page
    window.location.href = "/login";
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4 text-center">Examtech Expense Tracker</h1>

      <div className="mb-4 text-center">
        <button
          className="btn btn-primary me-2"
          onClick={() => setShowAddExpenseTypeModal(true)}
          data-bs-toggle="modal"
          data-bs-target="#addExpenseTypeModal"
        >
          Add Expense Type
        </button>
        <button
          className="btn btn-primary me-2"
          onClick={() => setShowAddParticipantModal(true)}
          data-bs-toggle="modal"
          data-bs-target="#addParticipantModal"
        >
          Add User
        </button>
        <button
          className="btn btn-primary me-2"
          onClick={() => setShowAddExpenseModal(true)}
          data-bs-toggle="modal"
          data-bs-target="#addExpenseModal"
        >
          Add Expense
        </button>
        <button className="btn btn-danger me-2" onClick={logout}>
          Logout
        </button>
      </div>

      <AddExpenseTypeModal
        show={showAddExpenseTypeModal}
        onClose={() => setShowAddExpenseTypeModal(false)}
        fetchExpenseTypes={fetchExpenseTypes}
      />
      <AddExpenseModal
        show={showAddExpenseModal}
        onClose={() => setShowAddExpenseModal(false)}
        fetchExpenses={fetchExpenses}
      />
      <AddParticipantModal
        show={showAddParticipantModal}
        onClose={() => setShowAddParticipantModal(false)}
        fetchParticipants={fetchParticipants}
      />

      <ExpenseDetailModal
        show={showExpenseDetailModal}
        onClose={() => setShowExpenseDetailModal(false)}
        expense={selectedExpense}
      />

      <div className="mt-4">
        <h2 className="text-center">Expenses</h2>

        <div className="mt-3 text-center">
          <div
            className="card text-white bg-primary mb-3"
            style={{ maxWidth: "18rem", margin: "0 auto" }}
          >
            <div className="card-header">Total Expenses</div>
            <div className="card-body">
              <h5 className="card-title" style={{ color: "black" }}>
                {totalExpenses} Rs
              </h5>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>Title</th>
                <th>Amount</th>
                <th>Paid by</th>
                <th>Paid to</th>
                <th>Date & Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {expenses.map((expense) => (
                <tr
                  key={expense.id}
                  onClick={() => handleRowClick(expense)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{expense.title}</td>
                  <td>{expense.amount} Rs</td>
                  <td>
                    {participants.find((p) => p.id === expense.payer_id)?.name}
                  </td>
                  <td>
                    {
                      participants.find((p) => p.id === expense.participant_id)
                        ?.name
                    }
                  </td>
                  <td>{expense.timestamp}</td>
                  <td>
                    <Link
                      to={`/edit-expense/${expense.id}`}
                      className="btn btn-sm btn-primary me-2"
                    >
                      Edit
                    </Link>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the row click event
                        deleteExpense(expense.id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-6">
          <h2 className="text-center">Participants</h2>
          <div className="row">
            {participants.map((participant) => (
              <div className="col-md-4 mb-3" key={participant.id}>
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title">{participant.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <h2 className="text-center">Expense Types</h2>
          <div className="row">
            {expenseTypes.map((expenseType) => (
              <div className="col-md-4 mb-3" key={expenseType.id}>
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title">{expenseType.type}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

import React, { useState } from "react";
import axios from "axios";

const AddParticipantModal = ({ show, onClose, fetchParticipants }) => {
  const [newParticipantName, setNewParticipantName] = useState("");

  const handleAddParticipant = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/participants`, {
        name: newParticipantName,
      });
      onClose();
      window.location.reload(true);
      // fetchParticipants();
    } catch (error) {
      console.error("Error adding participant:", error);
    }
  };

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      id="addParticipantModal"
      tabIndex="-1"
      aria-labelledby="addParticipantModalLabel"
      aria-hidden={!show}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addParticipantModalLabel">
              Add Participant
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="participantName" className="form-label">
                Participant Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="participantName"
                value={newParticipantName}
                onChange={(e) => setNewParticipantName(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAddParticipant}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParticipantModal;

import React from "react";

const ExpenseDetailModal = ({ show, onClose, expense }) => {
  if (!expense) return null;

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      id="expenseDetailModal"
      tabIndex="-1"
      aria-labelledby="expenseDetailModalLabel"
      aria-hidden={!show}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="expenseDetailModalLabel">
              Expense Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <h5>Title</h5>
            <p>{expense.title}</p>
            <h5>Amount</h5>
            <p>{expense.amount} Rs</p>
            <h5>Description</h5>
            <p>{expense.description}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseDetailModal;
